import config from '@/_content/config.json';

export default function useConfig() {
  function getConfig(key: string) {
    let value: any = config;

    for (const component of key.split('.')) {
      value = value[component];

      if (value === undefined) {
        console.warn(`Failed to find configuration object "${key}".`);
        break;
      }
    }

    return value;
  }

  function getSocial(key: string) {
    const target = config.socials.find(s => s.id === key);

    if (!target) {
      return '/';
    }

    return target.value;
  }

  function t(key: string): string {
    let text: any = config.texts;

    for (const component of key.split('.')) {
      text = text[component];

      if (text === undefined) {
        break;
      }
    }

    if (typeof text !== 'string') {
      console.warn(`Failed to find string key "${key}".`);
      return key;
    }

    return text;
  }

  return { getConfig, getSocial, t };
}
